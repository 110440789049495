import { getTinode, initializeChat, subscribeToChannel } from '@services/chat';
import React, { Suspense, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { IntlProvider } from "react-intl";
import "./base.css";

const ChatItGalaxy = ({
  username,
  channelsList,
  setShowModalDetails,
  setIds,
  projectId,
  projectType,
  type,
  setChannelSelected,
  setLoading,
}) => {
  const [chatInstance, setChatInstance] = useState(null);
  const [activeChannels, setActiveChannels] = useState([]);
  const [ItGalaxyChat, setItGalaxyChat] = useState(null);
  const language = "en";

  useEffect(() => {
    const loadChatComponent = async () => {
      try {
        const tinode = await getTinode();
        setItGalaxyChat(() => tinode.ChatComponent);
      } catch (error) {
        console.error('Error loading chat component:', error);
      }
    };
    loadChatComponent();
  }, []);

  useEffect(() => {
    const setupChat = async () => {
      try {
        setLoading(true);
        // Initialize chat using our service
        const chat = await initializeChat(username);
        setChatInstance(chat);

        // Subscribe to all channels
        const channelPromises = channelsList.map(channel =>
          subscribeToChannel(channel.id, (msg) => {
            // Handle message
            console.debug('New message:', msg);
          })
        );

        const channels = await Promise.all(channelPromises);
        setActiveChannels(channels);
        setLoading(false);
      } catch (error) {
        console.error('Error setting up chat:', error);
        setLoading(false);
      }
    };

    if (username && channelsList?.length > 0) {
      setupChat();
    }

    // Cleanup function
    return () => {
      if (chatInstance) {
        activeChannels.forEach(channel => {
          try {
            channel.unsubscribe();
          } catch (error) {
            console.debug('Error unsubscribing from channel:', error);
          }
        });
      }
    };
  }, [username, channelsList]);

  const handeldetails = (channelSelectedData) => {
    // Regular Expressions to capture the respective parts
    const regexId1 =
      /(COMPOSED_FREELANCE|SHARETASK|Contrat|Product|Recruitment|Contactor|AccountingJob)(\d+)/;
    const regexId2 =
      /(proposalentreprise|proposal|appeloffreproposal|soldedproduct|contactedCANDIDAT|recruted|contactedENTREPRISE|contactedRECRUTER|workingFolderCOMPANY|workingFolderREQUEST_COMPANY_CREATION)(\d+)/g;

    // Match projectId based on the first regex
    const id1Match = channelSelectedData.match(regexId1);
    // Match all occurrences for proposalId from the second regex
    const id2Matches = [...channelSelectedData.matchAll(regexId2)];

    // Capture the type and numeric part for projectId
    const extractedType = id1Match ? id1Match[1] : "Not found"; // Type
    const extractedProjectId = id1Match ? id1Match[2] : "Not found"; // Numeric part
    // Get last matched numeric part for proposalId
    const extractedProposalId =
      id2Matches.length > 0
        ? id2Matches[id2Matches.length - 1][2]
        : "Not found"; // Last match

    // Set the extracted values in state
    setIds({
      type: extractedType,
      projectId: extractedProjectId,
      proposalId: extractedProposalId,
    });
    setShowModalDetails(true);
  };

  return (
    <IntlProvider locale={language} textComponent={React.Fragment}>
      {channelsList && channelsList.length > 0 && (
        <Suspense fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              maxHeight: "97%",
              backgroundColor: "white",
              textAlign: "center",
              padding: "20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <div>
              <h2>Loading Chat...</h2>
              <Spinner />
            </div>
          </div>
        }>
          {ItGalaxyChat && (
            <ItGalaxyChat
              username={username}
              setChannelSelected={setChannelSelected}
              channelsList={channelsList}
              projectId={projectId}
              projectType={projectType}
              handeldetails={handeldetails}
              type={type}
              setLoading={setLoading}
            />
          )}
        </Suspense>
      )}
    </IntlProvider>
  );
};

export default ChatItGalaxy;
